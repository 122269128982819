import React from 'react'

import Layout from '../components/layout'
import Team from '../components/team'

const TeamPage = ({location}) => (
  <Layout location={location}>
    <h2>Team</h2>
    <Team />
  </Layout>
)

export default TeamPage
