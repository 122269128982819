import React from 'react'
import styled from 'styled-components'
import { Link, StaticQuery, graphql } from 'gatsby'

const TEAM_QUERY = graphql`
 query TeamListing {
    allMarkdownRemark(limit: 10, sort: {
    order: DESC,
    fields: [frontmatter___date]
    }, filter: {fileAbsolutePath: {regex: "/(team)/"  }}) {
      edges {
        node {
          frontmatter {
            title
            bio
            slug
          }
        }
      }
    }
  }
`
const Post = styled.article`
  box-shadow: 0px 3px 10px rgba(25, 17, 34, 0.05);
  padding: 1rem;
  border-radius: 4px;
  margin-bottom: 1rem;
  a {
    color: #000;
    text-decoration: none;
  }
  h2 {
    margin-bottom: 0;
  }
  p {
    font-size: 0.8rem;
  }
  .read-more {
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    font-size: 0.8rem;
    text-decoration: underline;
    color: #524763;
  }
`

const Team = () => (
  <StaticQuery
    query={TEAM_QUERY}
    render={({allMarkdownRemark}) => (
      allMarkdownRemark.edges.map(({node}) => (
        <Post key={node.frontmatter.slug}>
          <Link to={`/team${node.frontmatter.slug}`}>
            <h2>{node.frontmatter.title}</h2>
          </Link>
          <p>{node.frontmatter.bio}</p>
          <Link class="read-more" to={`/team${node.frontmatter.slug}`}>Read More</Link>
        </Post>
      ))
    )}
  />
)

export default Team
